<template>
  <div>

    <p v-if="errors.length">
      <b>Status:</b>
      <ul>
        <li v-for="error in errors" :key="error">{{ error }}</li>
      </ul>
    </p>

    <form @submit.stop.prevent="onSubmit" class="form">
     <div class="card-body">
      <h3 class="font-size-lg text-dark font-weight-bold mb-6">1. Informações Básicas:</h3>
      <div class="mb-15">

        <div class="form-group row">
          <label class="col-lg-3 col-form-label">Nome</label>
          <div class="col-lg-6">
            <div class="input-group">
              <div class="input-group-prepend"><span class="input-group-text"><i class="la la-chain"></i></span></div>
              <input v-model="sto_title" type="text" class="form-control" placeholder="Loja"/>
            </div>
          </div>
        </div>

        <div class="form-group row">
         <label class="col-lg-3 col-form-label">Descrição</label>
         <div class="col-lg-6">
           <b-form-textarea
             v-model="sto_description"
             placeholder="Digite uma descrição..."
             rows="3"
             max-rows="6"
             name="cat_description"
             
           ></b-form-textarea>
           <span class="form-text text-muted">Descreva a loja</span>
         </div>
        </div>

        <div class="form-group row">
          <label class="col-lg-3 col-form-label">Usuário</label>
          <div class="col-lg-6">
            <div class="input-group">
              <div class="input-group-prepend"><span class="input-group-text"><i class="la la-user"></i></span></div>
              <b-form-select v-model="sto_user_id"  :options="users_list" />
            </div>
          </div>
        </div>

        <div class="form-group row">
          <label class="col-lg-3 col-form-label">Categorias</label>
          <div class="col-lg-6">
            <div class="input-group">
              <div class="input-group-prepend"><span class="input-group-text"><i class="la la-object-group"></i></span></div>
              <b-form-select v-model="sto_categories_id"  :options="categories_list" multiple />
            </div>
          </div>
        </div>
      </div>

      <div class="form-group row">
         <label class="col-lg-3 col-form-label">Ícone</label>
         <img class="px-4 py-2" :src="sto_icon_path" width="100px">
         <div class="col-lg-6">
           <div class="input-group">
             <div class="input-group-prepend">
              <span class="input-group-text">
                <i class="flaticon2-image-file"></i>
              </span>
            </div>
             <input
              type="file"
              name="sto_icon"
              accept="image/*"
              id="sto_icon" 
              ref="sto_icon"
              @change="HandlerSto_icon"
              class="input-file"
              
              >
           </div>
         </div>
       </div>

      <div class="form-group row">
         <label class="col-lg-3 col-form-label">Capa</label>
         <img class="px-4 py-2" :src="sto_cover_path" width="100px">
         <div class="col-lg-6">
           <div class="input-group">
             <div class="input-group-prepend">
              <span class="input-group-text">
                <i class="flaticon2-image-file"></i>
              </span>
            </div>
             <input
              type="file"
              name="sto_cover"
              accept="image/*"
              id="sto_cover" 
              ref="sto_cover"
              @change="HandlerSto_cover"
              class="input-file"
              
              >
           </div>
        </div>
      </div>

       <div class="form-group row">
         <label class="col-lg-3 col-form-label">Fotos</label>
         <div class="img-wrap-list">
            <div  v-for="image_file_path in images_list_path" :key="image_file_path" class="img-wrap mx-4 my-2">
              <span class="close" @click="remove_image">&times;</span>
              <img :src="image_file_path" width="100px">
            </div>
          </div>
         <div class="col-lg-6">
           <div class="input-group">
             <div class="input-group-prepend">
              <span class="input-group-text">
                <i class="flaticon2-image-file"></i>
              </span>
            </div>
             <input
              type="file"
              name="sto_photo_data"
              accept="image/*"
              id="sto_photo_data" 
              ref="sto_photo_data"
              @change="handlePhotosDataUpload($event)"
              class="input-file"
              multiple="multiple"
              >
            
           </div>
         </div>
       </div>

       <div class="form-group row">
         <label class="col-lg-3 col-form-label">Cidade</label>
         <div class="col-lg-6">
           <div class="input-group">
             <div class="input-group-prepend"><span class="input-group-text"><i class="la la-map-marker"></i></span></div>
             <input v-model="sto_city" type="text" class="form-control" placeholder="Cidade"/>
           </div>
         </div>
       </div>

       <div class="form-group row">
         <label class="col-lg-3 col-form-label">UF</label>
         <div class="col-lg-6">
           <div class="input-group">
             <div class="input-group-prepend"><span class="input-group-text"><i class="la la-map-o"></i></span></div>
             <input v-model="sto_uf" type="text" class="form-control" placeholder="UF"/>
           </div>
         </div>
       </div>

       <div class="form-group row">
         <label class="col-lg-3 col-form-label">Telefone</label>
         <div class="col-lg-6">
           <div class="input-group">
             <div class="input-group-prepend"><span class="input-group-text"><i class="la la-phone-square"></i></span></div>
             <input v-model="sto_phone" type="text" class="form-control" placeholder="Telefone"/>
           </div>
         </div>
       </div>

       <div class="form-group row">
         <label class="col-lg-3 col-form-label">Celular</label>
         <div class="col-lg-6">
           <div class="input-group">
             <div class="input-group-prepend"><span class="input-group-text"><i class="la la-mobile-phone"></i></span></div>
             <input v-model="sto_cellphone" type="text" class="form-control" placeholder="Celular"/>
           </div>
         </div>
       </div>

       <div class="form-group row">
         <label class="col-lg-3 col-form-label">WebSite</label>
         <div class="col-lg-6">
           <div class="input-group">
             <div class="input-group-prepend"><span class="input-group-text"><i class="la la-globe"></i></span></div>
             <input v-model="sto_website" type="text" class="form-control" placeholder="WebSite"/>
           </div>
         </div>
       </div>

       <div class="form-group row">
         <label class="col-lg-3 col-form-label">Endereço Completo</label>
         <div class="col-lg-6">
           <div class="input-group">
             <div class="input-group-prepend"><span class="input-group-text"><i class="la la-thumb-tack"></i></span></div>
             <input v-model="sto_address" type="text" class="form-control" placeholder="Endereço Completo"/>
           </div>
         </div>
       </div>
     </div>
     <div class="card-footer">
      <div class="row">
       <div class="col-lg-3"></div>
       <div class="col-lg-6">
         <b-button v-if="$route.name == router_actions.route_new" type="submit" class="btn btn-success mr-2">Cadastrar</b-button>
         <b-button v-if="$route.name == router_actions.route_edit" type="submit" class="btn btn-success mr-2">Atualizar</b-button>
         <b-button type="reset" class="btn btn-secondary">Cancelar</b-button>
       </div>
      </div>
     </div>

    </form>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import ApiService from "@/core/services/api.service";
export default {
  name: "store",
  props: {
    id: String,
    router_actions: Object
  },
  mounted() {

    this.getUsers();
    this.getCategories();

    if(this.$route.name == this.router_actions.route_edit){
      this.$store.dispatch(SET_BREADCRUMB, [
        { title: "Dashboard", route: "/" },
        { title: "Listagem de Lojas", route: '/stores/list' },
        { title: "Editar Loja", route: '/stores/edit/'+this.$route.params.id }
        ]);
      let id = this.$route.params.id;
      ApiService.get('stores/'+id)
        .then(({data}) =>{
          this.sto_categories_id = data.sto_categories_id.split(',');
          this.sto_user_id = data.sto_user_id;
          this.sto_title = data.sto_title;
          this.sto_description = data.sto_description;
          this.sto_phone = data.sto_phone;
          this.sto_cellphone = data.sto_cellphone;
          this.sto_website = data.sto_website;
          this.sto_city = data.sto_city;
          this.sto_uf = data.sto_uf;
          this.sto_address = data.sto_address;
          this.sto_active = data.sto_active;
          var sto_icon = data.sto_icon
          this.sto_icon_path = this.base_url + 'images/' + id + '/' + sto_icon
          ApiService.get(this.sto_icon_path)
            .then(({data}) =>{
              var file = new File([data], sto_icon);
              this.sto_icon = file;
            })
            .catch((error) => {
              this.errors.push(error.message);
            });
          var sto_cover = data.sto_cover
          this.sto_cover_path = this.base_url + 'images/' + id + '/' + sto_cover
          ApiService.get(this.sto_cover_path)
            .then(({data}) =>{
              var file = new File([data], sto_cover);
              this.sto_cover = file;
            })
            .catch((error) => {
              this.errors.push(error.message);
            });
          this.images_list_path = [];
          let url = ''
          for(let image_file_path of data.sto_photo_data.split(',')){
            url = this.base_url + 'images/' + id + '/' + image_file_path
            this.images_list_path.push(url)
            this.filenames[url] = image_file_path
            ApiService.get(url)
              .then(({data}) =>{
                var file = new File([data], image_file_path);
                this.sto_photo_data.push(file);
              })
              .catch((error) => {
                this.errors.push(error.message);
              });
          }
        })
          .catch((error) => {
            this.errors.push(error.message);
          });
          
    }else{
      this.$store.dispatch(SET_BREADCRUMB, [
        { title: "Dashboard", route: "/" },
        { title: "Listagem de Lojas", route: '/stores/list' },
        { title: "Cadastrar Loja", route: '/stores/new/'}
        ]);

      this.sto_title = '';
      this.sto_description = '';
      this.sto_user_id = 0;
      this.sto_categories_id = [];
      this.sto_phone = '';
      this.sto_cellphone = '';
      this.sto_website = '';
      this.sto_city = '';
      this.sto_uf = '';
      this.sto_icon = null;
      this.sto_cover = null;
      this.sto_photo_data = [];
      this.sto_address = '';
      this.sto_active = true;
    }
  },
  components: {},
  data() {
    return {
      filenames : {},
      sto_title: '',
      sto_description: '',
      sto_user_id: 0,
      sto_categories_id: [],
      sto_phone: '',
      sto_cellphone: '',
      sto_website: '',
      sto_city: '',
      sto_uf:  '',
      sto_icon : null,
      sto_cover: null,
      sto_photo_data: [],
      sto_address: '',
      sto_active: true,
      sto_icon_path: '',
      sto_cover_path: '',
      images_list_path: [],
      users_list: [],
      categories_list: [],
      errors: [],
      base_url : 'http://api.prcweb.com.br/' //'http://localhost:4000/'
    };
  },
  methods: {
    FormReset() {
      this.sto_title = '';
      this.sto_description = '';
      this.sto_user_id = 0;
      this.sto_categories_id = [];
      this.sto_icon = null;
      this.sto_cover = null;
      this.sto_photo_data = [];
      this.sto_city = '';
      this.sto_uf = '';
      this.sto_phone = '';
      this.sto_cellphone = '';
      this.sto_website = '';
      this.sto_address = '';
      this.sto_active = true;
    },
    getUsers(){
      ApiService.get('users')
        .then(({data}) =>{
          this.users_list = data.map((curval, indice, array)=>{
            return {value: curval['id'], text: curval['usu_email']}
          })
        })
        .catch(({response}) => {
          this.users_list = [];
          this.errors.push(response.data.errors);
        });
    },
    getCategories(){
      ApiService.get('categories')
        .then(({data}) =>{
          this.categories_list = data.map((curval, indice, array)=>{
            return {value: curval['id'], text: curval['cat_title']}
          })


        })
        .catch(({response}) => {
          this.categories_list = [];
          this.errors.push(response.data.errors);
        });
    },
    HandlerSto_cover(event){      
      this.sto_cover_path = URL.createObjectURL(event.target.files[0])  
      this.sto_cover = event.target.files[0];
    },

    HandlerSto_icon(event){
      this.sto_icon_path = URL.createObjectURL(event.target.files[0])  
      this.sto_icon = event.target.files[0];
    },

    handlePhotosDataUpload(event){

      var url = ''

      for(var file of event.target.files){
        url = URL.createObjectURL(file)
        this.images_list_path.push(url);
        this.sto_photo_data.push(file);

        this.filenames[url] = file.name;
      }
    },

    remove_image(event){
        
      console.log('teste')
      
      let image_element = event.target.nextElementSibling;

      this.images_list_path = this.images_list_path.filter((item) => {
        return item != image_element.src
      })

      var file_name = this.filenames[image_element.src]

      this.sto_photo_data = this.sto_photo_data.filter((item) => {
        return item.name != file_name
      })
    },
    validateForm(){
      if(this.sto_title == ''){
        this.errors.push("O campo Nome é obrigatorio");
      }

      if(this.sto_description == ''){
        this.errors.push("O campo Descrição é obrigatorio");
      } 

      if(this.sto_user_id == ''){
        this.errors.push("O campo Usuário é obrigatorio");
      } 

      if(this.sto_categories_id == ''){
        this.errors.push("O campo Categorias é obrigatorio");
      }

      if(this.sto_icon == null){
        this.errors.push("O campo Ícone é obrigatorio");
      }

      if(this.sto_cover == null){
        this.errors.push("O campo Capa é obrigatorio");
      } 

      if(this.sto_city == null){
        this.errors.push("O campo Cidade é obrigatorio");
      } 

      if(this.sto_uf == null){
        this.errors.push("O campo Estado é obrigatorio");
      } 

      if(this.sto_phone == null){
        this.errors.push("O campo Telefone é obrigatorio");
      } 

      if(this.sto_cellphone == null){
        this.errors.push("O campo Celular é obrigatorio");
      } 

      if(this.sto_address == null){
        this.errors.push("O campo Endereço é obrigatorio");
      } 
    },
    onSubmit(e) {

      this.validateForm()

      let id;
      let rota = '';
      let metodo = null;
      if(this.errors.length){
        this.message = [];
      }

      let stores = {
        sto_categories_id: this.sto_categories_id,
        sto_title: this.sto_title,
        sto_description: this.sto_description,
        sto_user_id: this.sto_user_id,
        sto_phone: this.sto_phone,
        sto_cover: this.sto_cover,
        sto_photo_data: this.sto_photo_data,
        sto_icon: this.sto_icon,
        sto_cellphone: this.sto_cellphone,
        sto_website: this.sto_website,
        sto_city: this.sto_city,
        sto_uf: this.sto_uf,
        sto_address: this.sto_address,
        sto_active: this.sto_active,
        img_deleted:[]
      };

      if(this.$route.name == this.router_actions.route_edit){
        id = this.$route.params.id;
        rota = id;
        metodo = 'put';
      }else{
        metodo = 'post';
        rota = '';
      }    
      
      
      ApiService[metodo]('stores/'+rota, stores)
        .then(({data}) => {
          if(!this.errors.includes(data.message)){
            this.$router.push({ name: "stores-list" })
          }
        })
        .catch(({response}) => {
          if(!this.errors.includes(response.data.errors)){
            this.errors.push(response.data.errors);
          }
        });

      e.preventDefault();
    }
  },
};
</script>


<style>

.img-wrap {
  position: relative;
  display: inline-block;
  border: 1px red solid;
  font-size: 0;
}

.img-wrap-list{
  overflow:auto; 
}

.img-wrap .close {
  position: absolute;
  top: 2px;
  right: 2px;
  z-index: 100;
  background-color: #FFF;
  padding: 5px 2px 2px;
  color: #000;
  font-weight: bold;
  cursor: pointer;
  opacity: .2;
  text-align: center;
  font-size: 22px;
  line-height: 10px;
  border-radius: 50%;
}
.img-wrap:hover .close {
  opacity: 1;
}

</style>